//Colours: http://paletton.com/#uid=70U180kw0vSjzD3oSy0y9oLDhjs

@import "~bootstrap/scss/bootstrap";

$primary: #d5e14d; //Artisan's Gold
$secondary: #8B04A7; //purple
$tertiary: #1046A9; //Blue
$quatiry: #fea500; // Lime Green

$bg-color: #222;

$grid-gutter-width: 30px;

$website-width: 1200px;

$font-size-base: 15px;