@mixin transition($speed:0.5s) {
    transition: $speed all;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius; -moz-border-radius: $radius; border-radius: $radius;
}

@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow; -moz-box-shadow: $shadow; box-shadow: $shadow;
}

@mixin clear() {
	&:before {
		content: ""; display: table; width: 100%;
	}
	&:after {
		content: ""; display: table; width: 100%;
	}
}